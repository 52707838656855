import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


// Icons und Marker
import InnerIcon from '../LocationMap/InnerIcon';
import DefaultMarker from '../CustomMarker/DefaultMarker';
import IconChevronDown from '../../assets/images/featherIcons/IconChevronDown';

// Styles
import styles from './MapLegend.module.scss';
import accordionStyles from '../LocationList/LocationList.module.scss';

// Helper
import ColorMapping from '../LocationMap/ColorMapping';

const MapLegend = () => {
    const elements = [
        { categoryId: 'kooperationsverbund', label: 'Kooperationsverbund', key: 'koopv' },
        { categoryId: 'schule', label: 'Grundschule', key: '11' },
        { categoryId: 'schule', label: 'Oberschule', key: '12' },
        { categoryId: 'schule', label: 'Oberschule+', key: '15' },
        { categoryId: 'schule', label: 'Gymnasium', key: '13' },
        { categoryId: 'schule', label: 'Gemeinschaftsschule', key: '14' },
        { categoryId: 'schule', label: 'Schule nach § 63d SächsSchulG', key: '16' },
        { categoryId: 'schule', label: 'Berufsschule', key: '21' },
        { categoryId: 'schule', label: 'Berufsfachschule', key: '22' },
        { categoryId: 'schule', label: 'Berufliches Gymnasium', key: '25' },
        { categoryId: 'schule', label: 'Fachschule', key: '23' },
        { categoryId: 'schule', label: 'Fachoberschule', key: '24' },
        { categoryId: 'schule', label: 'Berufsbildende Förderschule', key: '28' },
        { categoryId: 'schule', label: 'Schule mit dem Förderschwerpunkt Lernen', key: '31' },
        { categoryId: 'schule', label: 'Schule mit dem Förderschwerpunkt Sehen', key: '31' },
        { categoryId: 'schule', label: 'Schule mit dem Förderschwerpunkt Hören', key: '32' },
        { categoryId: 'schule', label: 'Schule mit dem Förderschwerpunkt geistige Entwicklung', key: '33' },
        { categoryId: 'schule', label: 'Schule mit dem Förderschwerpunkt körperliche und motorische Entwicklung', key: '34' },
        { categoryId: 'schule', label: 'Schule mit dem Förderschwerpunkt Sprache', key: '32' },
        { categoryId: 'schule', label: 'Schule mit dem Förderschwerpunkt emotionale und soziale Entwicklung', key: '34' },
        { categoryId: 'schule', label: 'Klinik- und Krankenhausschule', key: '39' },
        { categoryId: 'schule', label: 'Abendoberschule', key: '41' },
        { categoryId: 'schule', label: 'Abendgymnasium', key: '41' },
        { categoryId: 'schule', label: 'Kolleg', key: '41' },
        { categoryId: 'schule', label: 'Ergänzungsschule', key: '58' },
        { categoryId: 'schule', label: 'Sonstige Bildungs- und Betreuungseinrichtung', key: '-' },
        { categoryId: 'schule', label: 'Weitere Bildungseinrichtung', key: 'Weitere Bildungseinrichtungen' },
        {
            categoryId: 'schule',
            label: 'Förderschule oder Förderzentrum mit Beratungsstelle',
            key: 'schoolCounselingCenter',
            subCategoryIds: 'schoolCounselingCenter',
        },
        { categoryId: 'einrichtung', label: 'Standort der Schulaufsicht', key: 'einrichtung', subCategoryIds: 'institutionSchoolSupervision' },

        { categoryId: 'einrichtung', label: 'Servicestelle Inklusionsassistent', key: 'einrichtung', subCategoryIds: 'institutionSchoolServiceCenter' },
        {
            categoryId: 'einrichtung',
            label: 'Landesbeauftragter für Inklusion der Menschen mit Behinderung (Clearingstelle)',
            key: 'einrichtung',
            subCategoryIds: 'institutionClearing',
            cssClass: styles.fullWidth,
        },
        {
            categoryId: 'einrichtung',
            label: 'Kompetenz- und Medienzentrum der Landesschule für Blinde und Sehbehinderte Chemnitz',
            key: 'einrichtung',
            subCategoryIds: 'Sehbehinderte',
            cssClass: styles.fullWidth,
        },
    ];

    return (
        <Accordion
            allowZeroExpanded={true}
        >
            <AccordionItem className={`${accordionStyles.placeItem} ${accordionStyles.accordionItem} ${styles.item}`}>
                <AccordionItemHeading className={accordionStyles.accordionHeading}>
                    <AccordionItemButton>
                        <span
                            className={[accordionStyles.flexContainer, styles.itemButton].join(' ')}
                            aria-labelledby={'place_title_legend'}
                        >
                            <div className={accordionStyles.titleWrapper}>
                                <span
                                    className={accordionStyles.title}
                                    id={'place_title_legend'}
                                >
                                    Zeichenerklärung
                                </span>
                            </div>
                            <span className={styles.arrow}>
                                <IconChevronDown aria-hidden />
                            </span>
                        </span>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className={styles.legend}>
                        {elements.map((element, index) => (
                            <div key={index} className={[styles.element, element.cssClass || ''].join(' ')}>
                                <DefaultMarker
                                    className={styles.defaultMarker}
                                    height="30"
                                    width="24"
                                    fillcolor={ColorMapping[element.key] || '#1A76D0'}
                                />
                                <InnerIcon
                                    categoryId={element.categoryId}
                                    subCategoryIds={[element.subCategoryIds]}
                                    className={[styles.customIcon].join(' ')}
                                />
                                <div className={styles.label}>{element.label}</div>
                            </div>
                        ),
                        )}
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default MapLegend;
