// siehe UM getSubCategoryIds
const ColorMapping = {
    '11': '#f19156',
    '12': '#614918',
    '13': '#00782c',
    '14': '#750d68',
    '15': '#614918',
    '16': '#e600af',
    '21': '#003788',
    '22': '#003788',
    '23': '#003788',
    '24': '#003788',
    '25': '#003788',
    '28': '#c1002b',
    '31': '#c1002b',
    '32': '#c1002b',
    '33': '#c1002b',
    '34': '#c1002b',
    '35': '#c1002b',
    '36': '#c1002b',
    '37': '#c1002b',
    '39': '#c1002b',
    '40': '#f1c356',
    '41': '#f1c356',
    '42': '#f1c356',
    '43': '#f1c356',
    '44': '#f1c356',
    '45': '#f1c356',
    'Weitere Bildungseinrichtungen': '#5f5f5f',
    'schoolCounselingCenter': '#c1002b',
    '58': '#069893',
    'einrichtung': '#b7d69b',
    'koopv': '#f29200',
};

export default ColorMapping;
