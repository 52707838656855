//essentials
import ReactDOMServer from 'react-dom/server';

import React, {
    useEffect,
    useContext,
    useRef,
    useCallback,
    useMemo,
} from 'react';

import L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { MapContainer, Marker, WMSTileLayer } from 'react-leaflet';
import Proj from 'proj4leaflet';
import i18n from '../../i18n';

//components
import { LocationContext } from '../LocationContext/LocationContext';
import LocationMapPopup from './LocationMapPopup';
import ClusteredMarkers from './ClusteredMarkers';
import CustomMarker from '../CustomMarker/CustomMarker';
import InnerIcon from './InnerIcon';

//handler
import MapPositionHandler from './MapPositionHandler';

//styles
import styles from './LocationMap.module.scss';
import IconShadow from '../../assets/images/icons/IconShadow';

//Map Basics
import {
    MAX_ZOOM,
    MIN_ZOOM,
    MAP_DEFAULTS,
} from './BasicMapProps';
import ColorMapping from './ColorMapping';

L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);


// eslint-disable-next-line react/prop-types
const LocationMap = ({ initialZoomLevel, map }) => {

    const {
        setOpenedLocationId,
        uniquePlace,
        setUniquePlace,
        locationObjects,
        openedLocationId,
    } = useContext(LocationContext);

    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);
    const markerRef = useRef();
    const markerClick = useCallback(
        ({ locationLat, locationLon, locationId }) => {
            if (isMounted()) {
                setUniquePlace({
                    locationLat: locationLat,
                    locationLon: locationLon,
                    place_id: locationId,
                });

                setOpenedLocationId(locationId);
            }
        },
        [isMounted, setOpenedLocationId, setUniquePlace],
    );

    useEffect(() => {
        isMountedRef.current = true;

        return () => (isMountedRef.current = false);
    }, []);

    const markerDom = useMemo(() => {

        return locationObjects?.map((marker) => {
            const {
                id,
                categoryId,
                subCategoryIds,
                lat,
                lon,
                name,
            } = marker;

            // const isActive = id === openedLocationId;
            const markerAction = () => markerClick({
                // name,
                locationLat: lat,
                locationLon: lon,
                locationId: id,
            });

            const fillColor = ColorMapping[(subCategoryIds || []).find((id) => {
                return Boolean(ColorMapping[id]);
            })] || '#1A76D0';

            return (
                <Marker
                    key={id}
                    ref={markerRef}
                    eventHandlers={{
                        click: markerAction,
                        keydown: (event) => event.originalEvent.key === 'Enter' && markerAction(),
                        mouseover: (event) => {
                            event.target.openPopup();
                        },
                        mouseout: (event) => event.target.closePopup(),
                    }}
                    position={[lat, lon]}
                    icon={L.divIcon({
                        className: styles.customMarker,
                        html: ReactDOMServer.renderToString(
                            <CustomMarker
                                title={`${name}`}
                                description={i18n.t(`categories.singular.${categoryId}`)}
                                locationCategory={categoryId}
                                subCategoryIds={subCategoryIds}
                                fillColor={fillColor}
                            >
                                <InnerIcon categoryId={categoryId} subCategoryIds={subCategoryIds} />
                                <IconShadow className={styles.shadow} />

                            </CustomMarker>,
                        ),
                    })}
                    marker={marker}
                />
            );
        });
    }, [locationObjects, markerClick]);

    const wmsLayerParams = useMemo(() => ({
        service: 'WMS',
        version: '1.3.0',
        request: 'GetMap',
        format: 'image/png',
        transparent: true,
        layers: 'Vegetation,Siedlung,Gewaesser,Verkehr,Administrative_Einheiten,Beschriftung',
        styles: 'palette_rgb_halbton,palette_rgb_halbton,palette_rgb_halbton,palette_rgb_halbton,palette_rgb_halbton,palette_rgb_halbton',
        CRS: 'EPSG:25832',
        uppercase: true,
        tileSize: 1000,
    }), []);

    return (
        <>
            <section className={styles.mapContainer}>
                <LocationMapPopup />
                <MapContainer
                    center={[MAP_DEFAULTS.lat, MAP_DEFAULTS.lng]}
                    zoom={initialZoomLevel}
                    scrollWheelZoom={false}
                    className={styles.map}
                    updateWhenZooming={true}
                    gestureHandling={true}
                    gestureHandlingOptions={{
                        duration: 1500,
                        text: {
                            touch: 'Benutzen Sie zwei Finger um die Karte zu bewegen.',
                            scroll: 'Benutzen Sie \'STRG\' + \'Scroll\' um in der Karte zu zoomen.',
                            scrollMac: 'Benutzen Sie \'\u2318\' + \'Scroll\' um in der Karte zu zoomen.',
                        },
                    }}
                    minZoom={MIN_ZOOM}
                    maxZoom={MAX_ZOOM}
                    zoomSnap={1}
                    zoomDelta={1}
                    crs={
                        new Proj.CRS(
                            'EPSG:25832',
                            '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
                            {
                                resolutions: [
                                    8192, 4096, 2048, 1024, 512, 256, 128,
                                    64, 32, 16, 8, 4, 2, 1, 0.5,
                                ],
                            },
                        )
                    }
                >

                    <WMSTileLayer
                        url="https://geodienste.sachsen.de/wms_geosn_webatlas-sn/guest?"
                        params={wmsLayerParams}
                    />
                    <MapPositionHandler
                        panPosition={uniquePlace}
                        markers={locationObjects}
                        openedLocationId={openedLocationId}
                    />
                    {markerDom?.length > 0 && (
                        <ClusteredMarkers
                            map={map}
                            locationObjects={locationObjects}
                            markerClick={markerClick}
                        />
                    )}
                </MapContainer>
            </section>
        </>
    );
};

export default LocationMap;
