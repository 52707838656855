import React from 'react';

export default function format(lines) {
    return lines.split('\n')
        .filter((line) => line.trim() !== '')
        .map((line, index) => {
            return (
                <li key={index}>{line.startsWith('- ') ? line.slice(2) : line}</li>
            );
        });
}
