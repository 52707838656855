import React, { useMemo, useState, createContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getInitialContent, getLocations } from '../../requests/locationRequests';
import i18n from '../../i18n';

export const LocationContext = createContext();

const defaultUniquePlace = {
    display_name: 'Sachsen Center',
    locationLat: '51.104541', // Sachsen Center
    locationLon: '13.201738', // Sachsen Center
    place_id: '9999999',
};

const LocationContextProvider = ({ children }) => {
    const [uniquePlace, setUniquePlace] = useState(defaultUniquePlace);
    const [filterValues, setFilterValues] = useState(
        {
            district: [],
            focalPointOfSupport: [],
            institutions: [],
            koopv: [],
            schoolAccessibility: [],
            schoolCategory: [],
            schoolCommunity: [],
            schoolInspectorate: [],
            schoolLegalStatus: [],
            schoolLocation: '',
            schoolMeasures: [],
            schoolName: '',
            schoolOwner: [],
            schoolServiceCenter: [],
        });
    const [filters, setFilters] = useState({});
    const [loadingState, setLoadingState] = useState('initial');
    const [errorMessage, setErrorMessage] = useState({ message: '' });
    const [openedLocationId, setOpenedLocationId] = useState('');

    const [locationObjects, setLocationObjects] = useState([]);
    const [koopvObjects, setKoopvObjects] = useState([]);
    const [currentView, setCurrentView] = useState('map');
    // const [promiseResolved, setPromiseResolved] = useState(false);
    const [resetMap, setResetMap] = useState(0);
    const topFunction = useCallback(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    const timeoutPromise = () => new Promise((_, reject) => {

        setTimeout(() => {
            const error = new Error('error');
            error.message = i18n.t('error.timeout');
            reject(error); // Reject with error after timeout
        }, 15000); // 15 seconds
    });

    // INITIAL REQUEST
    useEffect(() => {
        setErrorMessage('');
        setLoadingState('loading');

        const fetchData = async () => {

            try {
                const response = await Promise.race([getInitialContent(), timeoutPromise()]);
                if (response?.success) {
                    setKoopvObjects(response.data.entries);
                    setLocationObjects(response.data.entries);
                    setFilters(response.data.filters);
                    setLoadingState('success');
                } else {
                    setErrorMessage(i18n.t('error.noSuccess'));
                    setLoadingState('error');
                }
            } catch (error) {
                if (error?.status === 404) {
                    setErrorMessage(i18n.t('error.notFound'));
                    setLoadingState('error');
                }
                if (error?.status === 503) {
                    setErrorMessage(i18n.t('error.timeout'));
                    setLoadingState('error');
                }
                setErrorMessage(String(error?.message || error));
                setLoadingState('error');
            }
        };

        fetchData();
    }, []);

    // FILTERED REQUEST
    const loadFilteredResults = useCallback(async () => {
        setOpenedLocationId('');
        setErrorMessage('');
        setLoadingState('loading');

        try {
            let activeFilters = 0;
            Object.values(filterValues || {}).forEach((value) => {
                if (value?.length > 0) {
                    activeFilters += 1;
                }
            });

            if (activeFilters > 0) {
                const response = await Promise.race([getLocations(filterValues), timeoutPromise()]);
                setLocationObjects(response.data.entries);
                setLoadingState('success');
                topFunction();
                setResetMap(1 + resetMap);
            }
        } catch (error) {
            setErrorMessage(error?.message);
            setLoadingState('error');
        }
    }, [filterValues, topFunction, resetMap]);

    const resetAllFilters = useCallback(() => {
        setResetMap(1 + resetMap);

        setFilterValues({
            district: [],
            focalPointOfSupport: [],
            institutions: [],
            koopv: [],
            schoolAccessibility: [],
            schoolCategory: [],
            schoolCommunity: [],
            schoolInspectorate: [],
            schoolLegalStatus: [],
            schoolLocation: '',
            schoolMeasures: [],
            schoolName: '',
            schoolOwner: [],
            schoolServiceCenter: [],
        });
        setLocationObjects(koopvObjects);
    }, [koopvObjects, resetMap]);

    const contextProps = useMemo(() => {
        return {
            uniquePlace,
            setUniquePlace,
            openedLocationId,
            setOpenedLocationId,
            locationObjects,
            setLocationObjects,
            loadingState,
            currentView,
            setCurrentView,
            filterValues,
            setFilterValues,
            loadFilteredResults,
            filters,
            koopvObjects,
            errorMessage,
            setErrorMessage,
            resetAllFilters,
            resetMap,
            setResetMap,
        };
    }, [
        uniquePlace,
        setUniquePlace,
        openedLocationId,
        setOpenedLocationId,
        locationObjects,
        setLocationObjects,
        loadingState,
        currentView,
        setCurrentView,
        filterValues,
        setFilterValues,
        loadFilteredResults,
        filters,
        koopvObjects,
        errorMessage,
        setErrorMessage,
        resetAllFilters,
        resetMap,
    ]);

    return (
        <LocationContext.Provider
            value={contextProps}
        >
            {children}
        </LocationContext.Provider>
    );
};

export default LocationContextProvider;

LocationContextProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};
