const MAX_ZOOM = 15;
const MIN_ZOOM = 0;
const DEFAULT_ZOOM = 7;
const MAP_DEFAULTS = {
    lat: 51.004545,
    lng: 13.201740,
    zoom: 5,
};

export {
    MAX_ZOOM,
    MIN_ZOOM,
    DEFAULT_ZOOM,
    MAP_DEFAULTS,
};
