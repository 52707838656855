import React, { useContext, useMemo } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import i18n from '../../i18n';

// Components
import MultiSelect from '../MultiSelect/MultiSelect';

// Context
import { LocationContext } from '../LocationContext/LocationContext';

// Style & Icons
import styles from './LocationMapFilter.module.scss';

const LocationMapFilter = () => {
    const {
        filters,
        filterValues,
        setFilterValues,
        loadFilteredResults,
        resetAllFilters,
    } = useContext(LocationContext);

    const isButtonDisabled = useMemo(() => {
        let activeFilters = 0;

        Object.values(filterValues || {}).forEach((value) => {
            if (value?.length > 0) {
                activeFilters += 1;
            }
        });

        return activeFilters < 1;
    }, [filterValues]);

    const schoolTypeOptions = () => {
        const allAllowedSchoolCategories = (filterValues?.schoolCategory || filters.schoolCategory).map((obj) => obj.value);

        return filters?.schoolType?.options?.filter((obj) => allAllowedSchoolCategories.includes(obj.schoolCategoryKey)) || [];
    };

    const schoolTypeValues = () => {
        const allAllowedValues = schoolTypeOptions().map((obj) => obj.value);

        return filterValues?.schoolType?.filter((currentSelected) => allAllowedValues.includes(currentSelected.value)) || [];
    };

    // Filter accordion
    const filterAccordionDOM = (
        <div className={[styles.filterBlock, styles.filterBlockAccordion].join(' ')}>
            <Accordion
                preExpanded={['schoolCategoryTab']}
                allowZeroExpanded={true}
                allowMultipleExpanded={true}
                className={styles.accordion}
            >

                {/* Accordion Item - Schulart */}
                <AccordionItem className={styles.accordionItem} uuid={'schoolCategoryTab'}>
                    <AccordionItemHeading className={styles.accordionHeading}>
                        <AccordionItemButton className={styles.accordionButton}>
                            {i18n.t('filters.headlines.schoolCategory')}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className={styles.accordionPanel}>
                            <div className={styles.filterFieldset}>

                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'schoolCategory'}
                                    label={i18n.t('filters.names.schoolCategory')}
                                    options={filters?.schoolCategory?.options}
                                    placeholder={i18n.t('filters.placeholders.pleaseSelect')}
                                    isSearchable={false}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            schoolCategory: selected,
                                        });
                                    }}
                                    defaultValue={filterValues.schoolCategory}
                                    value={filterValues.schoolCategory}
                                />
                            </div>
                            <div className={styles.filterFieldset}>
                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'schoolType'}
                                    label={i18n.t('filters.names.schoolType')}
                                    options={schoolTypeOptions()}
                                    placeholder={i18n.t('filters.placeholders.pleaseSelect')}
                                    isSearchable={false}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            schoolType: selected,
                                        });
                                    }}
                                    defaultValue={schoolTypeValues()}
                                    value={schoolTypeValues()}
                                />
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>

                {/* Einrichtungen mit inklusiver Unterrichtung im Förderschwerpunkt */}
                <AccordionItem className={styles.accordionItem}>
                    <AccordionItemHeading className={styles.accordionHeading}>
                        <AccordionItemButton className={styles.accordionButton}>
                            {i18n.t('filters.headlines.focalPointOfSupport')}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className={styles.accordionPanel}>
                            <div className={styles.filterFieldset}>

                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'focalPointOfSupport'}
                                    label={i18n.t('filters.names.focalPointOfSupport')}
                                    options={filters?.focalPointOfSupport?.options}
                                    isSearchable={false}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            focalPointOfSupport: selected,
                                        });
                                    }}
                                    defaultValue={filterValues.focalPointOfSupport}
                                    value={filterValues.focalPointOfSupport}
                                />
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>

                {/* Erweitert */}
                <AccordionItem className={styles.accordionItem}>
                    <AccordionItemHeading className={styles.accordionHeading}>
                        <AccordionItemButton className={styles.accordionButton}>
                            {i18n.t('filters.headlines.extended')}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className={styles.accordionPanel}>
                            <div className={styles.filterFieldset}>

                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'schoolCommunity'}
                                    label={i18n.t('filters.names.schoolCommunity')}
                                    options={filters?.schoolCommunity?.options}
                                    isSearchable={true}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            schoolCommunity: selected,
                                        });
                                    }}
                                    defaultValue={filterValues.schoolCommunity}
                                    value={filterValues.schoolCommunity}
                                />
                            </div>
                        </div>
                        <div className={styles.accordionPanel}>
                            <div className={styles.filterFieldset}>

                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'schoolOwner'}
                                    label={i18n.t('filters.names.schoolOwner')}
                                    options={filters?.schoolOwner?.options}
                                    isSearchable={false}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            schoolOwner: selected,
                                        });
                                    }}
                                    defaultValue={filterValues.schoolOwner}
                                    value={filterValues.schoolOwner}
                                />
                            </div>
                            {/*<div className={styles.filterFieldset}>*/}

                            {/*    <MultiSelect*/}
                            {/*        className={styles.customMultiSelect}*/}
                            {/*        id={'schoolInspectorate'}*/}
                            {/*        label={i18n.t('filters.names.schoolInspectorate')}*/}
                            {/*        options={filters?.schoolInspectorate?.options}*/}
                            {/*        isSearchable={false}*/}
                            {/*        onChange={selected => {*/}
                            {/*            setFilterValues({*/}
                            {/*                ...filterValues,*/}
                            {/*                schoolInspectorate: selected,*/}
                            {/*            });*/}
                            {/*        }}*/}
                            {/*        defaultValue={filterValues.schoolInspectorate}*/}
                            {/*        value={filterValues.schoolInspectorate}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                        <div className={styles.accordionPanel}>
                            <div className={styles.filterFieldset}>

                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'schoolLegalStatus'}
                                    label={i18n.t('filters.names.schoolLegalStatus')}
                                    options={filters?.schoolLegalStatus?.options}
                                    isSearchable={false}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            schoolLegalStatus: selected,
                                        });
                                    }}
                                    defaultValue={filterValues.schoolLegalStatus}
                                    value={filterValues.schoolLegalStatus}
                                />
                            </div>
                            <div className={styles.filterFieldset}>

                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'schoolAccessibility'}
                                    label={i18n.t('filters.names.schoolAccessibility')}
                                    options={filters?.schoolAccessibility?.options}
                                    isSearchable={false}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            schoolAccessibility: selected,
                                        });
                                    }}
                                    defaultValue={filterValues.schoolAccessibility}
                                    value={filterValues.schoolAccessibility}
                                />
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>

                {/* Einrichtungen mit besonderen Aufgaben  */}
                <AccordionItem className={styles.accordionItem}>
                    <AccordionItemHeading className={styles.accordionHeading}>
                        <AccordionItemButton className={styles.accordionButton}>
                            {i18n.t('filters.headlines.institutions')}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className={styles.accordionPanel}>
                            <div className={styles.filterFieldset}>

                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'institutions'}
                                    label={i18n.t('filters.names.institutions')}
                                    options={filters?.institutions?.options}
                                    isSearchable={false}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            institutions: selected,
                                        });
                                    }}
                                    defaultValue={filterValues.institutions}
                                    value={filterValues.institutions}
                                />
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>

                {/* Maßnahmen */}
                <AccordionItem className={styles.accordionItem}>
                    <AccordionItemHeading className={styles.accordionHeading}>
                        <AccordionItemButton className={styles.accordionButton}>
                            {i18n.t('filters.headlines.schoolMeasures')}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className={styles.accordionPanel}>
                            <div className={styles.filterFieldset}>

                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'schoolMeasures'}
                                    label={i18n.t('filters.names.schoolMeasures')}
                                    options={filters?.schoolMeasures?.options}
                                    isSearchable={false}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            schoolMeasures: selected,
                                        });
                                    }}
                                    defaultValue={filterValues.schoolMeasures}
                                    value={filterValues.schoolMeasures}
                                />
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>

                {/* Beratungs- und Anlaufstellen für schulische Inklusion */}
                <AccordionItem className={styles.accordionItem}>
                    <AccordionItemHeading className={styles.accordionHeading}>
                        <AccordionItemButton className={styles.accordionButton}>
                            {i18n.t('filters.headlines.schoolServiceCenter')}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className={styles.accordionPanel}>
                            <div className={styles.filterFieldset}>

                                <MultiSelect
                                    className={styles.customMultiSelect}
                                    id={'schoolServiceCenter'}
                                    label={i18n.t('filters.names.schoolServiceCenter')}
                                    options={filters?.schoolServiceCenter?.options}
                                    isSearchable={false}
                                    onChange={selected => {
                                        setFilterValues({
                                            ...filterValues,
                                            schoolServiceCenter: selected,
                                        });
                                    }}
                                    defaultValue={filterValues.schoolServiceCenter}
                                    value={filterValues.schoolServiceCenter}
                                />
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );

    // Filter form
    const filterDOM = (
        <div className={styles.filterWrapper}>

            <div className={styles.filterBlock}>
                <div className={styles.filterFieldset}>
                    <MultiSelect
                        className={styles.customMultiSelect}
                        id={'koopv'}
                        label={i18n.t('filters.names.koopv')}
                        options={filters?.koopv?.options}
                        isSearchable={true}
                        onChange={selected => {
                            setFilterValues({
                                ...filterValues,
                                koopv: selected,
                            });
                        }}
                        defaultValue={filterValues.koopv}
                        value={filterValues.koopv}
                    />
                </div>
                <div className={styles.filterFieldset}>
                    <MultiSelect
                        className={styles.customMultiSelect}
                        id={'district'}
                        label={i18n.t('filters.names.district')}
                        options={filters?.district?.options}
                        isSearchable={true}
                        onChange={selected => {
                            setFilterValues({
                                ...filterValues,
                                district: selected,
                            });
                        }}
                        defaultValue={filterValues.district}
                        value={filterValues.district}
                    />
                </div>
            </div>
            <div className={styles.filterBlock}>
                <div className={styles.filterFieldset}>
                    <label htmlFor={'schoolName'}>{i18n.t('filters.names.schoolName')}</label>
                    <input
                        id={'schoolName'}
                        onChange={(e) => {
                            setFilterValues({ ...filterValues, [e.target.id]: e.target.value });
                        }}
                        value={filterValues.schoolName}
                        placeholder={i18n.t('filters.placeholders.schoolname')}
                    />
                </div>
                <div className={styles.filterFieldset}>
                    <label htmlFor={'schoolLocation'}>{i18n.t('filters.names.schoolLocation')}</label>
                    <input
                        id={'schoolLocation'}
                        onChange={(e) => {
                            setFilterValues({ ...filterValues, [e.target.id]: e.target.value });
                        }}
                        value={filterValues.schoolLocation}
                        placeholder={i18n.t('filters.placeholders.location')}
                    />
                </div>

            </div>

            {filterAccordionDOM}
            <div className={styles.buttonBox}>
                <button
                    aria-disabled={isButtonDisabled}
                    className={styles.searchButton}
                    type="button"
                    onClick={loadFilteredResults}
                >
                    {i18n.t('filters.search')}
                </button>

                <button
                    className={styles.searchButton}
                    type="button"
                    onClick={() => resetAllFilters()}
                >
                    {i18n.t('filters.resetFilters')}
                </button>
            </div>
        </div>
    );

    return (
        <div className={styles.locationMapFilter}>
            <div className={styles.headlineWrapper}>
                <h2 className={styles.filterHeadline}>{i18n.t('filters.filter')}</h2>
            </div>
            {filterDOM}
        </div>
    );
};

export default LocationMapFilter;
