import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import i18n from '../../i18n';

// Components

const MultiSelect = ({ id, options, label, onClick, onChange, className, placeholder, ...other }) => {

    const styles = {
        multiValue: (baseStyles) => ({
            ...baseStyles,
            padding: '3px 8px 3px 12px',
            backgroundColor: '#004E92',
            borderRadius: '15px',
            color: 'white !important',
        }),

        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
        }),
        multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
            ':hover': {
                color: 'red',
            },
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: 'dimGray',
        }),
    };

    return (
        <>
            <label id={`${id}_label`} htmlFor={`${id}_input`}>
                {label}
            </label>
            <Select
                id={id}
                inputId={`${id}_input`}
                options={options}
                isMulti
                onClick={onClick}
                onChange={onChange}
                className={className}
                placeholder={placeholder || i18n.t('filters.placeholders.pleaseSelect')}
                styles={styles}
                aria-labelledby={`${id}_label`}
                {...other}
            />

        </>
    );
};

MultiSelect.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
};

export default MultiSelect;
