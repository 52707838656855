import { useMap } from 'react-leaflet';
import { useContext, useEffect, useState } from 'react';
import { latLngBounds } from 'leaflet';
import PropTypes from 'prop-types';
import { LocationContext } from '../LocationContext/LocationContext';

//Map Basics
import {
    MAX_ZOOM,
    MIN_ZOOM,
    DEFAULT_ZOOM,
    MAP_DEFAULTS,
} from './BasicMapProps';


const MapPositionHandler = ({
    panPosition,
    markers,
    openedLocationId,
}) => {
    const map = useMap();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const { resetMap } = useContext(LocationContext);

    useEffect(() => {
        map.invalidateSize();
    }, [map, openedLocationId]);

    useEffect(() => {
        map?.flyTo(
            { lat: MAP_DEFAULTS.lat, lng: MAP_DEFAULTS.lng },
            MAP_DEFAULTS.zoom);
    }, [map, resetMap]);

    useEffect(() => {
        if (openedLocationId) {
            map.whenReady(() => {
                panPosition?.locationLat
        && panPosition?.locationLon
        && window.setTimeout(() => {
            const currentZoom = map?.getZoom();
            const zoom = Math.max(MIN_ZOOM, Math.min(currentZoom || DEFAULT_ZOOM, MAX_ZOOM));

            map?.flyTo(
                {
                    lat: panPosition.locationLat,
                    lng: panPosition.locationLon,
                },
                zoom,
            );
        }, 250);
            });
        } else {
            map.whenReady(() => {
                const markerBounds = latLngBounds([]);
                // markerBounds.extend([51.0, 14.9]); //approximate boundaries of Saxony
                // markerBounds.extend([51.0, 12]); //approximate boundaries of Saxony

                markers?.forEach((marker) => {
                    if (marker.lat && marker.lon) {
                        markerBounds.extend([
                            marker.lat,
                            marker.lon,
                        ]);
                    }
                });

                if (markerBounds.isValid() && isInitialLoad) {
                    map?.fitBounds(markerBounds, { padding: [25, 25] });
                    setIsInitialLoad(false);
                }
            });
        }
    }, [isInitialLoad, map, markers, openedLocationId, panPosition, panPosition.locationLat, panPosition.locationLon]);

    return null;
};

MapPositionHandler.propTypes = {
    panPosition: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    markers: PropTypes.arrayOf(PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.arrayOf(PropTypes.number),
            PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        ])),
    ),
    openedLocationId: PropTypes.string,
};

export default MapPositionHandler;
