import React, { useContext } from 'react';
import i18n from '../../i18n';

// Components
import LocationMap from '../LocationMap/LocationMap';
import LocationList from '../LocationList/LocationList';
import TabSwitch, { Tab } from '../TabSwitch/TabSwitch';
import LocationMapFilter from '../LocationMapFilter/LocationMapFilter';
import Spinner from '../Spinner/Spinner';
import MapLegend from '../MapLegend/MapLegend';

// Context
import { LocationContext } from '../LocationContext/LocationContext';

// Styles
import styles from './LocationIndex.module.scss';

const LocationIndex = () => {
    const {
        setCurrentView, locationObjects,
        loadingState,
        errorMessage,
    } = useContext(LocationContext);

    const infoLine = () => {

        if (loadingState === 'error' && errorMessage !== '') {

            return (
                <div>
                    {errorMessage}
                </div>
            );
        }

        return (
            <div>
                {locationObjects.length
        + ' '
    + i18n.t('filters.foundEntries')}
            </div>
        );
    };

    const infolineDOM = (
        <div className={styles.infoline}>
            {loadingState === 'loading' || loadingState === 'initial' ? <Spinner />
                : infoLine()}
        </div>
    );

    return (
        <div className="locationIndex">
            <main>
                <TabSwitch
                    onChange={key => {
                        setCurrentView(key);
                    }}
                    infoline={infolineDOM}
                >
                    <Tab title="Kartenansicht" name="map">
                        <LocationMap initialZoomLevel={5} />
                    </Tab>
                    <Tab title="Listenansicht (barrierefrei)" name="list" />
                </TabSwitch>
                <MapLegend />
                <LocationMapFilter />
                <LocationList />
            </main>
        </div>
    );
};

export default LocationIndex;
