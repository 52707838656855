import React from 'react';
import './assets/stylesheets/index.scss';

import LocationContextProvider from './Components/LocationContext/LocationContext';
import LocationIndex from './Components/LocationIndex/LocationIndex';

function App() {
    return (
        <LocationContextProvider>
            <LocationIndex />
        </LocationContextProvider>
    );
}

export default App;
