//essentials
import PropTypes from 'prop-types';

import ReactDOMServer from 'react-dom/server';

import React, {
    useContext,
    useEffect,
    useRef,
} from 'react';

import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Marker, useMap, Popup } from 'react-leaflet';
import i18n from '../../i18n';

//components
import { LocationContext } from '../LocationContext/LocationContext';
import CustomMarker from '../CustomMarker/CustomMarker';
import InnerIcon from './InnerIcon';

//styles
import styles from './LocationMap.module.scss';
import IconShadow from '../../assets/images/icons/IconShadow';
import ColorMapping from './ColorMapping';


const ClusteredMarkers = ({ markerClick }) => {
    const clusterGroupRef = useRef(null);
    const map = useMap();
    const {
        locationObjects,
    } = useContext(LocationContext);

    useEffect(() => {
        const triggerMarkerClick = (event) => {
            const { target } = event;
            const classNames = target?.className || '';

            if (typeof classNames === 'string' && classNames?.split(' ').indexOf('popOverButton') > -1) {
                const { lat, lon, id } = target.dataset;

                markerClick({
                    locationLat: lat,
                    locationLon: lon,
                    locationId: id,
                });
            }
        };

        document.addEventListener('click', triggerMarkerClick);

        return () => {
            document.removeEventListener('click', triggerMarkerClick);
        };
    }, [markerClick, map]);

    const handleClusterMouseOver = (event) => {
        const cluster = event.layer;
        const center = cluster.getLatLng();

        const clusteredMarkers = cluster.getAllChildMarkers().map((marker) => {

            return marker.options.marker;
        });

        // add a link from ne new Function like markerAction()
        const clusteredMarkersListDOM = clusteredMarkers.map((marker, index) => {
            return (
                <li key={index}>
                    <button
                        data-lat={marker.lat}
                        data-lon={marker.lon}
                        data-id={marker.id}
                        className={[styles.btn, 'popOverButton'].join(' ')}
                        type="button"
                    >
                        {marker.name}
                    </button>
                </li>
            );
        });

        L.popup()
            .setLatLng(center)
            .setContent(`<div><ul class="clusterlist">${ReactDOMServer.renderToString(clusteredMarkersListDOM)}</ul></div>`)
            .openOn(map);
    };

    const markerDom = locationObjects?.map((marker) => {
        const {
            id,
            categoryId,
            subCategoryIds,
            lat,
            lon,
            name,
        } = marker;

        const markerAction = () => markerClick({
            // name,
            locationLat: lat,
            locationLon: lon,
            locationId: id,
        });

        const fillColor = ColorMapping[(subCategoryIds || []).find((id) => {
            return Boolean(ColorMapping[id]);
        })] || '#1A76D0';

        return (
            <Marker
                key={id}
                eventHandlers={{
                    click: markerAction,
                    keydown: (event) => event.originalEvent.key === 'Enter' && markerAction(),
                    mouseover: (event) => {
                        event.target.openPopup();
                    },
                    mouseout: (event) => event.target.closePopup(),
                }}
                position={[lat, lon]}
                icon={L.divIcon({
                    className: styles.customMarker,
                    html: ReactDOMServer.renderToString(
                        <CustomMarker
                            title={name}
                            description={i18n.t(`categories.singular.${categoryId}`)}
                            locationCategory={categoryId}
                            fillcolor={fillColor}
                        >
                            <InnerIcon categoryId={categoryId} subCategoryIds={subCategoryIds} />
                            <IconShadow className={styles.shadow} />
                        </CustomMarker>,
                    ),
                })}
                marker={marker}
            >
                <Popup>
                    <div className={styles.description}>{i18n.t(`categories.singular.${categoryId}`)}</div>
                    <div className={styles.title}>{name}</div>
                </Popup>
            </Marker>
        );
    });

    return (
        <MarkerClusterGroup
            ref={clusterGroupRef}
            chunkedLoading
            spiderfyOnMaxZoom={true}
            spiderfyDistanceMultiplier={2}
            showCoverageOnHover={false}
            iconCreateFunction={(cluster) => {
                return L.divIcon({
                    html: `<div>
              <div>
                  <span>${cluster.getChildCount()}</span>
              </div>
             
          </div>`,
                    className: styles.customMarkerCluster,
                    iconSize: L.point(33, 33, true),
                });
            }}
            onMouseOver={handleClusterMouseOver}
        >
            {markerDom}
        </MarkerClusterGroup>
    );
};

ClusteredMarkers.propTypes = {
    markerClick: PropTypes.func,
};

export default ClusteredMarkers;
