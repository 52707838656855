import React from 'react';
import PropTypes from 'prop-types';
import styles from './CustomMarker.module.scss';

// Icon bzw. Marker
import DefaultMarker from './DefaultMarker';

const CustomMarker = ({
    fillcolor,
    height = '60.645',
    width = '48.627',
    children,
    centeredMarker,
    locationCategory,
}) => {
    const cssClasses = [
        styles.markerWrapper,
        centeredMarker ? styles.centered : '',
        styles[locationCategory],
    ];

    return (
        <div
            className={cssClasses.join(' ')}
        >
            <DefaultMarker fillcolor={fillcolor} height={height} wdth={width} />
            <div className={styles.imageWrapper}>{children}</div>
        </div>
    );
};

CustomMarker.propTypes = {
    fillcolor: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    children: PropTypes.node,
    centeredMarker: PropTypes.bool,
    locationCategory: PropTypes.string,
};

export default CustomMarker;
