import React, { useMemo, useEffect, useContext, useRef, useCallback } from 'react';
import { LocationContext } from '../LocationContext/LocationContext';

//styles
import styles from './LocationMapPopup.module.scss';

//Icons
import IconX from '../../assets/images/featherIcons/IconX';
import {
    koopvContactPerson,
    koopvWorkingGoal,
    koopvWorkFocus,
    koopvEvent,
    koopvPartners,
    koopvSchoolCounts,
} from '../LocationDetails/koopDetails';
import {
    schoolCategoryKey,
    schoolType,
    schoolAddress,
    schoolHomepage,
} from '../LocationDetails/schoolDetails';
import { institutionAddress, institutionContactPerson } from '../LocationDetails/institutionDetails';


const LocationMapPopup = () => {
    const {
        openedLocationId,
        setOpenedLocationId,
        locationObjects,
    } = useContext(LocationContext);
    const popupContent = useRef(null);
    const closeBtnRef = useRef(null);
    const fadeLayer = useRef(null);
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);

    const markerContent = useMemo(() => {
        return locationObjects.find(el => el.id === openedLocationId) || {};
    }, [openedLocationId, locationObjects]);

    useEffect(() => {
        openedLocationId && closeBtnRef.current?.focus();
    }, [openedLocationId, closeBtnRef]);

    useEffect(() => {
        isMountedRef.current = true;

        return () => isMountedRef.current = false;
    }, []);

    const onScroll = () => {
        const scrollTop = popupContent.current.scrollTop;
        const offsetHeight = popupContent.current.offsetHeight;
        const height = popupContent.current.scrollHeight;

        if (fadeLayer && fadeLayer?.current?.style) {
            if (scrollTop + offsetHeight >= height) {
                fadeLayer.current.style.opacity = '0';
                fadeLayer.current.style.transform = `translateY(${scrollTop}px)`;
            } else {
                fadeLayer.current.style.opacity = '1';
                fadeLayer.current.style.transform = `translateY(${
                    scrollTop + 10
                }px)`;
            }
        }

    };

    const detailsDOM = (categoryId) => {
        // KOOPERTIONSVERBUND
        if (categoryId === 'kooperationsverbund') {
            return (
                <div className={styles.container}>
                    {/* contact_person */}
                    {koopvContactPerson(markerContent, styles)}
                    <hr className={styles.hr} />

                    {/* work_goal */}
                    {koopvWorkingGoal(markerContent, styles)}
                    <hr className={styles.hr} />

                    {/* work_focus */}
                    {koopvWorkFocus(markerContent, styles)}
                    <hr className={styles.hr} />

                    {/* event */}
                    {koopvEvent(markerContent, styles)}
                    <hr className={styles.hr} />

                    {/* partners */}
                    {koopvPartners(markerContent, styles)}
                    <hr className={styles.hr} />

                    {/* school_counts */}
                    {koopvSchoolCounts(markerContent, styles)}
                </div>
            );
        }
        // SCHULE
        if (markerContent.categoryId === 'schule') {
            return (
                <div className={styles.container}>
                    {/* categoryKey / categoryLabel */}
                    {schoolCategoryKey(markerContent, styles)}
                    <hr className={styles.hr} />

                    {schoolType(markerContent, styles)}
                    <hr className={styles.hr} />

                    {/* address */}
                    {schoolAddress(markerContent, styles)}
                    <hr className={styles.hr} />

                    {/* homepage */}
                    {schoolHomepage(markerContent, styles)}
                </div>
            );
        }

        // EINRICHTUNG
        if (markerContent.categoryId === 'einrichtung') {
            return (
                <div className={styles.container}>
                    {/* contact_person / contact_data */}
                    {institutionContactPerson(markerContent, styles)}
                    <hr className={styles.hr} />

                    {/* address */}
                    {institutionAddress(markerContent, styles)}
                    <hr className={styles.hr} />
                </div>
            );
        }
    };

    return (
        <div
            className={`${styles.popupWrapper} ${
                openedLocationId && styles.opened
            }`}
            key={openedLocationId}
            ref={popupContent}
            onScroll={() => onScroll()}
        >
            <div className={styles.nav}>
                <div className={`${styles.title} h4`}>{markerContent.name}</div>
                <div className={styles.buttonContainer}>
                    <button
                        type="button"
                        onClick={() => isMounted() && setOpenedLocationId('')}
                        className={styles.closeButton}
                        aria-label={'Popup schließen'}
                        ref={closeBtnRef}
                    >
                        <IconX />
                    </button>
                </div>
            </div>
            {detailsDOM(markerContent.categoryId)}
        </div>
    );
};

export default LocationMapPopup;
